import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"

import { ErrorBanner } from "./ErrorBanner"

export function StarterSunsetBanner() {
  const { t } = useTranslate()

  const Action = (
    <MButtonLegacy
      appHref={Routes.ChangePlan.location({
        discountCode: "UPGRADE2024",
      })}
    >
      {t(langKeys.sunset_starter_banner_action)}
    </MButtonLegacy>
  )

  return (
    <ErrorBanner
      title={t(langKeys.sunset_starter_banner_title, { discount: undefined })}
      description={t(langKeys.sunset_starter_banner_body, {
        discount: undefined,
      })}
      action={Action}
    />
  )
}
