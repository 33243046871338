import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { PLAN, SUBSCRIPTION_STATUS } from "src/data/user/user"
import { Logger } from "src/utils/logger"

export const starterSunsetLogger = new Logger({
  enabled: false, // change to 'true' if you want to debug
  prefix: "[starter sunset]",
})

export function useShowStarterSunset() {
  const { created_at, current_plan, subscription_status } = useGetUser()
  const { orgAccessLogic } = useOrganization()

  const isOwner = orgAccessLogic.hasOwnerAccess
  const hasStarterPlan = current_plan === PLAN.starter
  const accountCreatedBeforeDeadline = accountCreatedBefore1June2022(created_at)
  const hasNotHadSubscription = subscription_status === SUBSCRIPTION_STATUS.none

  const rules = {
    isOwner,
    hasStarterPlan,
    accountCreatedBeforeDeadline,
    hasNotHadSubscription,
  }
  const showStarterSunset: boolean = Object.values(rules).every(Boolean)

  starterSunsetLogger.log(rules, { showStarterSunset })

  return { showStarterSunset }
}

export function accountCreatedBefore1June2022(created: string) {
  const cutoffDate = new Date("2022-06-1")
  const createdDate = new Date(created)
  return createdDate < cutoffDate
}
